import useQuery from 'hooks/use-query'
import { getProducts } from './queries.graphql'

const useProducts = ({
  coachId,
  excludeLiveVideo = false,
  excludeMultiMonth = false,
} = {}) => {
  const productsPayload = useQuery(getProducts, {
    variables: {
      coachId,
      excludeLiveVideo,
      excludeMultiMonth,
    },
  })

  return productsPayload
}

export default useProducts

import { ReactNode } from 'react'
import { Heebo } from 'next/font/google'

import { BaseFontProvider } from './base-font-provider'

const heebo = Heebo({
  weight: ['300', '400', '500', '900'],
  subsets: ['latin'],
  display: 'swap',
})

export const HeeboProvider = ({ children }: { children: ReactNode }) => {
  return <BaseFontProvider font={heebo}>{children}</BaseFontProvider>
}

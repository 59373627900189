import cx from 'classnames'

import Spinner from 'components/shared/spinner'
import s from './styles.module.css'

export type LoadingScreenProps = {
  className?: string
  fullScreen?: boolean
  padded?: boolean
  transparent?: boolean
}

const LoadingScreen = ({
  fullScreen = false,
  padded = false,
  className,
  transparent = false,
}: LoadingScreenProps) => {
  return (
    <>
      <div className={transparent ? s.transparentBackground : ''}></div>
      <div
        className={cx(
          s.screen,
          {
            [s.fullScreen]: fullScreen,
            [s.padded]: padded,
            [s.transparent]: transparent,
          },
          className
        )}>
        <Spinner />
      </div>
    </>
  )
}

export default LoadingScreen

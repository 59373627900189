import * as R from 'ramda'

import useProducts from 'hooks/use-products'
import Product from './product'
import s from './styles.module.css'

const ProductSelectStandard = ({
  onSelect,
  submittingProductId,
  productClassName,
  currentProductId,
  coupon,
  coachId,
  canSelectCurrent = false,
}) => {
  const productsPayload = useProducts({ coachId, excludeMultiMonth: true })
  const products = productsPayload.data?.products || []

  return (
    <ul className={s.products}>
      {R.map(
        product => (
          <li key={product.id} className={s.product}>
            <Product
              product={product}
              onSelect={() => onSelect(product, product.prices[0])}
              submitting={submittingProductId === product.id}
              productClassName={productClassName}
              isCurrent={currentProductId === product.id}
              showRecommended={!currentProductId}
              canSelectCurrent={canSelectCurrent}
              coupon={coupon}
            />
          </li>
        ),
        R.take(3, products)
      )}
    </ul>
  )
}

export default ProductSelectStandard

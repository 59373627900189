import * as R from 'ramda'
import { useMemo } from 'react'
import cx from 'classnames'

import {
  DETAILS_BY_CALLS_PER_WEEK,
  BILLING_FREQUENCY_TEXT_BY_BILLING_PERIOD_IN_MONTHS,
  BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS,
} from 'constants/products'
import {
  getDailyPrice,
  getDiscountedPrice,
  getCouponNote,
} from 'utilities/stripe'
import { formatCurrency } from 'utilities/numbers'
import Spinner from 'components/shared/spinner'
import ProductDetails from 'components/shared/product-details'
import checkIcon from 'images/check-purple.svg'
import CallFrequencySelect from './call-frequency-select'
import s from './styles.module.css'

const Product = ({
  onSelect,
  isCurrentCallsPerWeek,
  currentBillingPeriodInMonths,
  showRecommended,
  canSelectCurrent,
  coupon,
  hasPrevLivePlusProduct,
  hasNextLivePlusProduct,
  selectPrevLivePlusProduct,
  selectNextLivePlusProduct,
  showCallFrequencySelect,
  isLoading = false,
  hideRecommendedBorder = false,
  billingPeriodInMonths,
  product,
  product: { name, descriptiveName, callsPerWeek, isRecommended },
  isFreeTrial,
}) => {
  const price = useMemo(() => {
    return R.find(
      R.propEq('billingPeriodInMonths', billingPeriodInMonths),
      product.prices
    )
  }, [product, billingPeriodInMonths])
  const isCurrent =
    isCurrentCallsPerWeek &&
    price.billingPeriodInMonths === currentBillingPeriodInMonths
  const billingFrequencyText =
    BILLING_FREQUENCY_TEXT_BY_BILLING_PERIOD_IN_MONTHS[billingPeriodInMonths]
  const billingTermText =
    BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS[billingPeriodInMonths]

  // no percent discount coupons for multi-month subscriptions
  if (billingPeriodInMonths > 1 && coupon && coupon.percentOff) {
    coupon = null
  }

  const [dailyPriceInDollars] = getDailyPrice({ price, coupon })
  const firstTermPriceInDollars = Math.round(
    getDiscountedPrice({
      price,
      coupon,
      isFirstTerm: true,
    }) / 100
  )
  const secondTermPriceInDollars = Math.round(
    getDiscountedPrice({
      price,
      coupon,
      isFirstTerm: false,
    }) / 100
  )
  const isOneTermDiscount = firstTermPriceInDollars !== secondTermPriceInDollars

  const getDetailContent = idx => {
    if (!showCallFrequencySelect || isFreeTrial) return null
    if (idx !== 1) return null

    return (
      <div className={s.callFrequencySelect}>
        <CallFrequencySelect
          value={callsPerWeek}
          hasPrev={hasPrevLivePlusProduct}
          hasNext={hasNextLivePlusProduct}
          onPrev={selectPrevLivePlusProduct}
          onNext={selectNextLivePlusProduct}
        />
      </div>
    )
  }

  return (
    <div
      className={cx(s.product, {
        [s.productRecommended]:
          showRecommended && isRecommended && !hideRecommendedBorder,
      })}>
      <div className={s.main}>
        <h4 className={s.name}>{descriptiveName || name}</h4>
        <div className={s.dailyPrice}>
          <h6 className={s.dailyPriceValue}>
            ${isFreeTrial ? 0 : dailyPriceInDollars}
          </h6>
          <div className={s.dailyPriceLabel}> / day</div>
          {isFreeTrial && (
            <div className={s.freeTrialText}>(Free 1 Month Trial)</div>
          )}
        </div>
        {isOneTermDiscount && (
          <div className={s.price}>
            First {billingTermText} only{' '}
            {formatCurrency(firstTermPriceInDollars, {
              minimumFractionDigits: 0,
            })}{' '}
            - then{' '}
            {formatCurrency(secondTermPriceInDollars, {
              minimumFractionDigits: 0,
            })}{' '}
            billed {billingFrequencyText}
          </div>
        )}
        {!isOneTermDiscount && (
          <div className={s.price}>
            {isFreeTrial && 'First month free - then '}
            {formatCurrency(firstTermPriceInDollars, {
              minimumFractionDigits: 0,
            })}{' '}
            billed {billingFrequencyText}
            {getCouponNote({ coupon })}
          </div>
        )}
        <ProductDetails
          details={DETAILS_BY_CALLS_PER_WEEK[callsPerWeek]}
          detailItemStyle={s.detailItem}
          textStyle={s.detailText}
          boldTextStyle={s.detailTextBold}
          checkIcon={
            <div>
              <img src={checkIcon} alt="Check" className={s.checkIcon} />
            </div>
          }
          getContent={getDetailContent}
        />
      </div>
      {isCurrent && <div className={s.currentPlanNote}>Current plan</div>}
      {(canSelectCurrent || !isCurrent) && (
        <button
          onClick={() => onSelect(price)}
          className={cx(s.submitButton, {
            [s.submitButtonRecommended]: showRecommended && isRecommended,
          })}>
          {!isLoading && (
            <>Select {R.replace('Kickoff ', '', name || descriptiveName)}</>
          )}
          {isLoading && <Spinner className={s.spinner} />}
        </button>
      )}
    </div>
  )
}

export default Product
